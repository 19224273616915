<template>
  <v-card>
    <v-card-title class="text-h6">Chọn hình nền</v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col cols="6" v-for="(image, index) in images" :key="`img_${index}`">
          <div
            class="bg-item d-flex align-center justify-center"
            :style="`background-image: url(${image});`"
            @click="setBackground(image)"
          >
            <v-icon color="success" v-if="selectedImage == image"
              >mdi-checkbox-marked-circle</v-icon
            >
          </div>
        </v-col>
        <v-col cols="6">
          <div
            class="bg-item bg-item-none d-flex align-center justify-center"
            @click="setBackground(null)"
          >
            <v-icon color="success" v-if="selectedImage == null"
              >mdi-checkbox-marked-circle</v-icon
            >
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ConfigBackground",
  data: () => ({
    images: [],
    selectedImage: null,
  }),
  computed: {},
  mounted() {
    this.images = [
      `${process.env.VUE_APP_FILE_CDN_URL}/background/bg1.png`,
      `${process.env.VUE_APP_FILE_CDN_URL}/background/bg2.png`,
      `${process.env.VUE_APP_FILE_CDN_URL}/background/bg3.jpeg`,
    ];
    this.selectedImage = localStorage.getItem("sib") || null;
  },
  beforeDestroy() {},
  methods: {
    close() {
      this.$emit("close");
    },
    setBackground(url) {
      this.selectedImage = url;
      this.$emit("setBackground", url);
      if (!url) {
        localStorage.removeItem("sib");
      } else {
        localStorage.setItem("sib", url);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bg-item {
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
}

.bg-item-none {
  border: 1px solid #333;
}
</style>
